<template>
  <b-row>
    <b-col cols="12">
        <b-card-code title="New Role & Permission">
        <!-- User Info: Input Fields -->
        <validation-observer ref="simpleRules">
    <b-form>
      <b-row>

        <!-- Field: role_name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Role Name"
            label-for="role_name"
          >
               <validation-provider
                #default="{ errors }"
                name="Role Name"
                rules="required"
              > 
            
            <b-form-input
              id="role_name"
              v-model="roleData.name"
            />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col> 
      </b-row>
  
    <!-- PERMISSION TABLE -->
      <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
      :fields="fields"
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      > 
          <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
            
         <b-form-checkbox
      :id="data.item.name"
      v-model="rolePermissionIds"
      :name="data.item.name"
      :value="data.item.id"
      unchecked-value="not_accepted"
    >
      {{ data.item.name }}
    </b-form-checkbox>
        </template>
      </b-table>
    </b-card>  

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
       @click.prevent="updateRolePermission"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
     </b-form>
</validation-observer>
        </b-card-code>
         
   </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ADMIN_PERMISSION_LIST,ADMIN_ROLE_SHOW } from "@core/services/api";
import {
  BCardText,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
import router from "@/router";
// Use toast
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  components: {
    BCardCode,
    BCardText,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true,
    // },
  },
  setup(props) {
    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64;
      }
    );

    return {
      avatarText,
      roleOptions,
      statusOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  data() {
    return {
      roleId: router.currentRoute.params.roleId,
      roleData:"",
      name: null,
      permissionsData: null,
      fields: ["Name"],
      permissionIds: [],
      required,
      rolePermissionIds: [], 
    };
  },
  methods: {
      getRoleData() {
      this.$http.get(process.env.VUE_APP_BASEURL + ADMIN_ROLE_SHOW + this.roleId)
          .then(res => {
                let instance = this;  
                this.roleData = res.data.data
                this.roleData.permissions.forEach(per => {
                instance.rolePermissionIds.push(per.id);
                })
                console.log(this.rolePermissionIds);
          }).catch(error => {
             console.log(error);
          // if (error.response.status === 404) {
          //     userData.value = undefined
          // }
      })
     },
    listPermissionData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_PERMISSION_LIST, {
          params: {},
        })
        .then((res) => {
          this.permissionsData = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
    updateRolePermission() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .updateRoleWisePermission(this.roleId,{
              role_id:this.roleId,
              permissions: this.rolePermissionIds ?? null,
              // name: this.roleData.name ?? null, 
            })
            .then((response) => {
              console.log(response); 
              if (checkRes(response.status) && response.data.code) { 
                this.$router
                  .push({ name: "admin-role-list" })
                  .catch((success) => {
                    this.toastMessage(
                      "success",
                      "Role Permission Add Successfully",
                      response
                    );
                  });
              } else {
                this.$router
                  .push({ name: "admin-role-add" })
                  .then(() => {
                    this.toastMessage("warning", "Something Wrong", response);
                  })
                  .catch((error) => {
                    this.toastMessage("danger", "Something Wrong", response);
                  });
              }
            });
        }
      });
    },
  },
  mounted() {
    this.listPermissionData();
    this.getRoleData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
